<template>
  <div class="table-item" :key="name">
    <div class="table-tile" :title="name">
      <div class="table-tile__item">
        <div class="table-tile__item-block block-media">
          <img-product
          class="product_img pr-2"
          :imgPath="image"
          :alt="name"
          :styleImage="{ maxWidth: '100%', width:'100px', height:'auto' }"
          />
          <span class="name" v-html="name"></span>
        </div>
        <div class="table-tile__item-block block-name">
          <span class="name" v-html="name"></span>
        </div>
        <div class="d-block d-md-none table-tile__item-block block-price">
          <span class="value">
            <span v-if="item.row_total_with_discount" class="discount pr-2">{{$store.state.storeConfig.config.base_currency_code}} {{ item.row_total_with_discount.toFixed(2) }}</span>
            {{$store.state.storeConfig.config.base_currency_code}} {{ item.row_total_incl_tax.toFixed(2)}}
          </span>
        </div>
        <div class="table-tile__item-block block-color">
          <div class="d-flex d-md-block align-items-md-center">
            <div class="label text-lowercase">{{ $t('color') }}</div>
            <span class="color-box order-2 order-md-1" :style="getStyleColorBox"></span>
            <span class="color-label">{{color.value}}</span>
          </div>
        </div>
        <div class="table-tile__item-block block-size" v-if="size">
          <div class="d-flex d-md-block align-items-md-center">
            <span class="label text-lowercase">{{$t('size')}}</span>
            <span class="value">{{ size.value }}</span>
          </div>
        </div>
        <div class="d-none d-md-block table-tile__item-block block-price">
          <span class="value">
            <span v-if="item.row_total_with_discount" class="discount pr-2">{{$store.state.storeConfig.config.base_currency_code}} {{ item.row_total_with_discount.toFixed(2) }}<br /></span>
            {{$store.state.storeConfig.config.base_currency_code}} {{ item.price_incl_tax.toFixed(2)}}
          </span>
        </div>
        <div class="table-tile__item-block block-qty">
          <div class="d-flex d-md-block align-items-md-center">
            <div class="label text-lowercase">{{ $t('qta') }}</div>
            <span class="value">{{ item.qty_ordered }}</span>
          </div>
        </div>
        <div class="d-none d-md-block table-tile__item-block block-subtotal block-latest">
          <div class="label">{{ $t('order.total') }}:</div>
          <span class="value">{{$store.state.storeConfig.config.base_currency_code}} {{ item.row_total_incl_tax.toFixed(2)}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find'
import ImgProduct from 'theme/components/Product/Image'

export default {
  name: 'TableProduct',
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      toggleDt: false,
      guest: false
    }
  },
  computed: {
    image () {
      return this.item.extension_attributes.image_url
    },
    name () {
      return this.item.name
    },
    sku () {
      return this.item.sku
    },
    color () {
      if (this.item && this.item.extension_attributes.selected_options) {
        return find(this.item.extension_attributes.selected_options, { 'attribute_code': 'color_detail' })
      }
      return false
    },
    size () {
      if (this.item && this.item.extension_attributes.selected_options) {
        return find(this.item.extension_attributes.selected_options, { 'attribute_code': 'size' })
      }
      return false
    },
    getStyleColorBox () {
      return this.color.color_image && this.color.color_image.includes('#')
        ? `background: #${this.color.color_image.split('#')[1]}`
        : `background: center / contain url(${this.color.color_image})`
    }
  },
  methods: {
    optionAvailable (maxQty) {
      return Array.from({ length: maxQty }, (v, k) => k + 1)
    },
    isValid () {
      return this.$validator.validateAll()
    },
    toggleDetail () {
      this.toggleDt = !this.showDetails
      const _this = this
      $(_this.$refs.moreInfo).slideToggle(_this.toggleDt)
    }
  },
  components: {
    ImgProduct
  }
}
</script>
