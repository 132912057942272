<template>
  <b-card no-body class="order-card" :class="{'enable-block-tracks': tracks.length > 0}">
    <b-card-header header-tag="header" role="tab" class="order-card__header" :class="{'collapsed': isCollapsed}" @click.prevent="isCollapsed = !isCollapsed">
      <div class="order-card__header-block block-order-id">
        <span class="label">{{ $t('orderSummary.order') }}</span>
        <span class="text">#{{order.increment_id}}</span>
      </div>
      <div class="order-card__header-block block-status">
        <span class="label">{{ $t('orderSummary.status') }}</span>
        <span class="text">{{order.status}}</span>
      </div>
      <div class="order-card__header-block block-creation">
        <span class="label">{{ $t('orderSummary.date') }}</span>
        <span class="text">{{ createdAt }}</span>
      </div>
      <div class="order-card__header-block block-total">
        <span class="label">{{ $t('orderSummary.total') }}</span>
        <span class="text">{{$store.state.storeConfig.config.base_currency_code}} {{ order.grand_total.toFixed(2) }}</span>
      </div>
      <div class="order-card__header-block block-tracks">
        <template v-if="tracks.length > 0">
          <a :href="track" target="_black" v-for="(track, index) in tracks" :key="index">
            {{ $t('orderSummary.followYourOrder')}} <span v-show="index">({{index}})</span>
          </a>
        </template>
        <span v-else>{{$t('orderSummary.noTracks')}}</span>
      </div>
      <div class="order-card__header-block block-collapse-arrow" :class="{'collapsed': isCollapsed}">
        <i :class="['icomoon-icon', isCollapsed ? 'icon-minus' : 'icon-plus']"></i>
      </div>
    </b-card-header>
    <b-collapse
      v-model="isCollapsed"
      :id="`accordion-${order.entity_id}`"
      accordion="my-accordion"
      role="tabpanel"
      class="order-collapse"
    >
      <order-item-body :order="order"></order-item-body>
      <table-products :items="order.items"></table-products>
    </b-collapse>
  </b-card>
</template>

<script>
import dayjs from 'dayjs'
import OrderItemBody from './OrderItemBody'
import TableProducts from './TableProducts.vue'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    tracks: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isCollapsed: false
    }
  },
  computed: {
    createdAt () {
      return dayjs(this.order.created_at).format('DD/MM/YYYY')
    }
  },
  components: {
    OrderItemBody,
    TableProducts
  }
}
</script>
