<template>
  <b-modal
    v-model="showModal"
    :hide-footer="true"
    @hide="hideModal"
    modal-class="modal-qapla"
    id="modal-qapla"
    size="xl"
  >
    <iframe
      :src="srcIframe"
      name="iframe_qapla"
      height="600px"
      width="100%"
      style="border-width: 0"
    ></iframe>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true
    },
    srcIframe: {
      type: String,
      default: '',
      required: true
    }
  },
  methods: {
    hideModal () {
      this.$store.commit('pl_qapla/toggleModalTracking')
    }
  }
}
</script>
