<template>
  <div class="order-addresses">
    <div class="order-addresses__title">
      <span class="text">{{ title }}</span>
    </div>
    <div class="order-addresses__content">
      <div class="order-addresses__content_lines">
        <div class="line">
          <p class="value-line">{{ address.firstname }} {{ address.lastname }}</p>
          <p class="value-line">{{ shippingAddressFieldsStreet }}</p>
          <p class="value-line">{{ address.city }}{{address.region ? ', ' + address.region : ''}}, {{ address.postcode }} {{ address.country_id }}</p>
          <p class="value-line">T: {{ address.telephone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    address: {
      type: Object,
      required: true
    }
  },
  computed: {
    shippingAddressFieldsStreet () {
      return this.address.street.join(' ') // API
    }
  }
}
</script>
