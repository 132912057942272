<template>
  <div class="customer-care-pages follow-your-order-page">
    <b-row class="layout-with-shoulder">
      <b-col cols="12" class="shoulder-left">
      <div  class="stiky-position">
        <customer-care-link/>
      </div>
      </b-col>
      <b-col cols="12" class="shoulder-content">
        <follow-order-page></follow-order-page>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FollowOrderPage from 'plugins/Qapla/routes/pages/FollowOrderPage'
import CustomerCareLink from 'theme/components/CustomerCare/CustomerCareLink.vue'

export default {
  components: {
    FollowOrderPage,
    CustomerCareLink
  },
  beforeRouteEnter (to, from, next) {
    next(componentInstance => {
      if (!componentInstance.$store.getters['user/isLogged']) next()
      else next({ name: 'myOrders' })
    })
  }
}
</script>
