<template>
  <div :class="customClass">
    <h3 class="title">{{ $t("qapla.result") }}</h3>
    <div v-if="orders" class="list">
      <order-item v-for="(order, index) in orders" :order="order" :tracks="tracksLink" :key="index"></order-item>
    </div>
  </div>
</template>

<script>
import OrderItem from './OrderItem'
export default {
  name: 'Results',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    orders: {
      type: Array,
      default: () => []
    },
    tracksLink: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  components: {
    OrderItem
  }
}
</script>
