<template>
  <div class="qapla-table-products">
    <div class="table-header">
      <div class="single-field d-none d-md-block"
        v-for="field in fields"
        :key="field.key"
      >
        <span>{{field.label}}</span>
      </div>
    </div>
    <div class="table-content">
      <div ref="rmaProducts">
        <div class="table-item" v-for="(item, index) in getConfigurableProducts" :key="index | generateKeyByRandomInRange">
          <table-product :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableProduct from './TableProduct'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('orderSummary.productName')
        },
        {
          key: 'color',
          label: this.$t('orderSummary.color')
        },
        {
          key: 'size',
          label: this.$t('orderSummary.size')
        },
        {
          key: 'price',
          label: this.$t('orderSummary.price')
        },
        {
          key: 'qty',
          label: this.$t('orderSummary.quantity')
        },
        {
          key: 'total',
          label: this.$t('orderSummary.totalItem')
        }
      ]
    }
  },
  components: {
    TableProduct
  },
  computed: {
    getConfigurableProducts () {
      return this.items.filter(item => item.product_type === 'configurable')
    }
  }
}
</script>
